<template>
  <el-select ref="treeSelect" 
  :size="size" 
  :multiple="true"
  :value="value"
  @change="selectChange"
  :clearable="clearable" 
  @clear="clearHandle" :style="{width:width}">
    <el-input :size="size" class="selectInput" :placeholder="placeholder" v-model="filterText"></el-input>
    <el-option value="">
      <el-tree
        id="tree-option"
        ref="selectTree"
        :accordion="accordion"
        :data="optionData"
        :props="props"
        :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
      ></el-tree>
    </el-option>
    <el-option
      v-for="item in selectedItems" :key="item.id"
      :value="item.id" :label="item.name" style="display:none;"></el-option>
  </el-select>
</template>
<script>
export default {
  name: "el-multi-tree-select",
  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => {
        return {
          value: "id", // ID字段名
          label: "name", // 显示名称
          children: "children" // 子级字段名
        };
      }
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
      default: () => {
        return [];
      }
    },
    /* 初始值 */
    value: {
      type: Array,
      default: () => {
        return null;
      }
    },
    size: {
      type: String,
      default: () => {
        return "small";
      }
    },    
    width: {
      type: String,
      default: () => {
        return "200px";
      }
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return "检索关键字";
      }
    }
  },
  computed: {
    /* 转树形数据 */
    optionData() {
      //第一个节点为根节点
      if (this.options.length > 0) {
        //var rootId = this.options[0].id;

        let cloneData = JSON.parse(JSON.stringify(this.options)); // 对源数据深度克隆
        
        // return cloneData.filter(father => {
        //   // 循环所有项，并添加children属性
        //   let branchArr = cloneData.filter(
        //     child => father.id == child.parentId
        //   ); // 返回每一项的子级数组
        //   branchArr.length > 0 ? (father.children = branchArr) : ""; //给父级添加一个children属性，并赋值
        //   return father.id == rootId || father.parentId == null; //返回第一层
        // });
        return this.listToTree(cloneData);
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      filterText: "",
      selectedItems: [{
        id: "",
        name: ""
      }],
      defaultExpandedKey: [],
      optionMap: {}
    };
  },
  mounted() {
    this.initHandle();
  },
  methods: {
    listToTree(list) { // 将普通列表转换为树结构的列表
      if (!list || !list.length) {
        return []
      }
      
      let treeListMap = {};
      
      for (let item of list) {
        treeListMap[item.id] = item
      }

      for (let i = 0; i < list.length; i++) {
        if (list[i].parentId && treeListMap[list[i].parentId]) {
          if (!treeListMap[list[i].parentId].children) {
            treeListMap[list[i].parentId].children = []
          }
          treeListMap[list[i].parentId].children.push(list[i]);
          list.splice(i, 1);
          i--
        }
      }

      return list;
    },
    // 初始化值
    initHandle() {
      console.log("options.length=" + this.options.length);

      this.selectedItems = [];

      if(this.value!=null){
        for(var i=0;i<this.value.length;i++){
          //var arr = this.options.filter(item=>item.id == this.value[i]);
          var id = this.value[i];
          var name = this.optionMap[id];

          this.selectedItems.push({
            id: id,
            name: name
          });
        }
      }

      // if(this.valueId){
      //   this.$refs.selectTree.setCurrentKey(this.valueId); // 设置默认选中
      //   this.defaultExpandedKey = [this.valueId]; // 设置默认展开
      // }

      this.initScroll();
    },
    // 初始化滚动条
    initScroll() {
      this.$nextTick(() => {
        let scrollWrap = document.querySelectorAll(
          ".el-scrollbar .el-select-dropdown__wrap"
        )[0];
        let scrollBar = document.querySelectorAll(
          ".el-scrollbar .el-scrollbar__bar"
        );
        scrollWrap.style.cssText =
          "margin: 0px; max-height: none; overflow: hidden;";
        scrollBar.forEach(ele => (ele.style.width = 0));
      });
    },
    // 切换选项
    handleNodeClick(node) {
      var name = node[this.props.label];
      var id = node[this.props.value];

      if(this.value!=null){
        var arr = this.value.filter(item=>item == id);

        console.log("arr.length=" + arr.length);
        
        if(arr.length==0){
          this.value.push(id);

          //已对value进行监视
          //this.$emit("input", this.value);
        }
      }
      else{
        this.$emit("input", [id]);
      }
    },
    // 清除选中
    clearHandle() {
      this.selectedItems = [];
      this.defaultExpandedKey = [];
      this.clearSelected();
      this.$emit("input", null);
    },
    selectChange(newVal) {
      console.log(newVal);
      this.$emit("input", newVal);
    },
    /* 清空选中样式 */
    clearSelected() {
      let allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach(element => element.classList.remove("is-current"));
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    }
  },
  watch: {
    value(newVal,oldVal) {
      this.valueId = newVal;

      if(newVal=='') {
        this.clearHandle();
      }

      this.initHandle();
    },
    options(newVal,oldVal) {
      //如果options读取慢，则
      if(oldVal==null || oldVal.length==0){
        setTimeout(()=>{
          this.initHandle();
        },100);
      }

      if(newVal!=null){
        newVal.forEach(item=>{
            var name = item[this.props.label];
            var id = item[this.props.value];

            this.optionMap[id] = name;            
        });
      }
    },
    filterText(val) {
      this.$refs.selectTree.filter(val);
    }
  }
};
</script>
<style>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
  color: #409eff;
  font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}
.selectInput {
  padding: 0 5px;
  box-sizing: border-box;
}
</style>