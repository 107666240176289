<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">园区管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/companyElectricityMeter">电表管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="公司名称" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyId"
            height="300"
            size="mini"
          ></el-select-tree>
      </el-form-item>
      <el-form-item label="电表编号" prop="meterNo">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.meterNo"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >添加</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="companyName"
        label="公司名称"
        width="140"
      ></el-table-column>
      <el-table-column
        prop="meterNo"
        label="电表编号"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="电表名称"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="ampereMax"
        label="电流最大值（A）"
        width="180"
      ></el-table-column>
            <el-table-column
        prop="voltageMax"
        label="电压最大值（V）"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="kwh"
        label="累计用电量(度)"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="lastOnlineTime"
        label="最后在线时间"
        width="160"
      ></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="{ row }">
          <el-button size="mini" type="warning" @click="handleEdit(row)"
            >编辑</el-button
          >
          <el-button size="mini" type="primary" @click="handleRelation(row)"
            >单位关联</el-button
          >
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <companyElectricityMeter-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></companyElectricityMeter-detail>
    <companyMeterRelation-detail
      v-if="showModal2"
      :businessKey="businessKey"
      :title="modalTitle2"
      @close="onDetailModalClose2"
    ></companyMeterRelation-detail>
  </div>
</template>
<script>
import companyElectricityMeterDetail from "./companyElectricityMeter-detail";
import companyMeterRelationDetail from "./companyMeterRelation-detail";
import companyElectricityMeterApi from "@/api/base/companyElectricityMeter";
import dataDictionaryApi from "@/api/sys/dataDictionary";
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";

export default {
  name: "BaseCompanyElectricityMeterList",
  data() {
    var self = this;

    return {
      queryModel: {
        companyId: "",
        meterNo: "",
      },
      tableData:[],
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showModal2: false,
      modalTitle2: "",
      businessKey: "",
      cardStatusData: [],
      treeData: [],
      companyResult:[],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    var self = this;

    var formData = new FormData();
    formData.append("catalogName", "社区卡片状态");
    dataDictionaryApi.findByCatalogName(formData).then((response) => {
      var jsonData = response.data;
      this.cardStatusData = jsonData.data;
    });

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      if(self.queryModel.companyId==null){
        self.queryModel.companyId="";
      }
      formData.append("companyId", self.queryModel.companyId);
      formData.append("meterNo", self.queryModel.meterNo);

      companyElectricityMeterApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleRelation(record){
      this.modalTitle2 = "单位关联";
      this.businessKey = record.id;
      this.showModal2 = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          companyElectricityMeterApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        companyElectricityMeterApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    onDetailModalClose2(refreshed) {
      //保存成功后回调
      this.showModal2 = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "companyElectricityMeter-detail": companyElectricityMeterDetail,
    "companyMeterRelation-detail": companyMeterRelationDetail,
    "el-select-tree": SelectTree
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>