
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    width="600px"
    @close="closeDialog"
    :close-on-click-modal="false"
  > 
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="单位名称" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="300"
            style="width:300px"
            size="mediumn"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="电表编号" prop="meterNo">
          <el-input v-model="formModel.meterNo" placeholder="请输入电表编号" style="width:300px"></el-input>
        </el-form-item>
                <el-form-item label="电表名称" prop="name">
          <el-input v-model="formModel.name" placeholder="请输入电表名称" style="width:300px"></el-input>
        </el-form-item>
        <el-form-item label="电流最大值（A）" prop="ampereMax">
          <el-input-number v-model="formModel.ampereMax"  placeholder="电流最大值" style="width:300px"></el-input-number>
        </el-form-item>
        <el-form-item label="电压最大值（V）" prop="voltageMax">
          <el-input-number  v-model="formModel.voltageMax" placeholder="电流最大值" style="width:300px"></el-input-number>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import companyInfoApi from "@/api/base/companyInfo";
import companyElectricityMeterApi from "@/api/base/companyElectricityMeter";
import SelectTree from "@/components/SelectTree";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
        meterNo: [{ required: true, message: "不能为空", trigger: "blur" }],
        name: [{ required: true, message: "不能为空", trigger: "blur" }],
        ampereMax: [{ required: true, message: "不能为空", trigger: "blur" }],
        voltageMax: [{ required: true, message: "不能为空", trigger: "blur" }]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {},
      rangeTime: "",
      treeData: [],
      companyResult:[],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            self.loading = true;

            if (self.formModel.companyId == null) {
              self.formModel.companyId = "";
            }

            if (id == null || id.length == 0) {
              return companyElectricityMeterApi.add(self.formModel);
            } else {
              return companyElectricityMeterApi.update(self.formModel);
            }
          })().then(function(response) {
            self.loading = false;

            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    (function() {
      if (self.businessKey.length == 0) {
        return companyElectricityMeterApi.create();
      } else {
        return companyElectricityMeterApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree
  }
};
</script>