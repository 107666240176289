
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align:left;"
    width="600px"
    @close="closeDialog"
    :close-on-click-modal="false"
  > 
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="单位名称" prop="companyId">
          <el-multi-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="300"
            style="width:300px"
            size="mediumn"
          ></el-multi-select-tree>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import companyInfoApi from "@/api/base/companyInfo";
import companyMeterRelationApi from "@/api/base/companyMeterRelation";
import MultiSelectTree from "@/components/MultiSelectTree";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        companyId: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {
        companyId:[],
        meterId:"",
      },
      rangeTime: "",
      treeData: [],
      companyResult:[],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      }
    };
  },
  created() {
    var self = this;
    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            self.loading = true;

            if(self.formModel.companyId!=null&&self.formModel.companyId.length!=0){
                self.formModel.companyId=self.formModel.companyId.toString();
            }

            return companyMeterRelationApi.add(self.formModel);

          })().then(function(response) {
            self.loading = false;

            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close", false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    (function() {
        return companyMeterRelationApi.edit(self.businessKey);
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel.meterId = self.businessKey;
          var list = jsonData.data;

          var companyArray = new Array();
          list.forEach((item,index) => {
              companyArray.push(item.companyId);
          });


          self.formModel.companyId = companyArray;

        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-multi-select-tree": MultiSelectTree
  }
};
</script>